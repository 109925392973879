import request from '../common/request';

// 违规查询
export const getDataByTypeId = (params) => request({
  url: '/api/desktop/deskItemDetectRecord/getDataByTypeId',
  method: 'get',
  params:{
    ...params
  }
});
