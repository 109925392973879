<template>
  <div class="violation">
    <h4 class="title">行为标准化检测系统检测报告</h4>
    <van-icon color="#f00" name="warning" class="warn"> 行为标准化检测到你有一条{{ detail.type | formatType }}异常</van-icon>
    <van-divider>{{ detail.type | formatType }}</van-divider>
    <van-grid :border="false" :column-num="2">
      <van-grid-item @click="prevImg(detail.beginImg)">
        <van-image :src="detail.beginImg" />
        <span class="date">{{ detail.startTime }}</span>
      </van-grid-item>
      <van-grid-item @click="prevImg(detail.endImg)">
        <van-image :src="detail.endImg" />
        <span class="date">{{ detail.endTime }}</span>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import { getDataByTypeId } from "@/api/desktop";
import { getQueryVariable } from "@/common/utils";
import { Icon, Image,Grid, GridItem ,Divider,ImagePreview  } from "vant";
import { mapState } from "vuex";
export default {
  name: "violation",
  components: {
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Divider.name]: Divider,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      detail: {}
    };
  },
  filters: {
    formatType(type) {
      switch (type) {
        case 1:
          return '玩手机'
        case 2:
          return '离岗脱岗'
        case 3:
          return '桌面物品摆放'
        default:
          return '未知'
      }
    }
  },
  mounted() {
    getDataByTypeId({
      id: getQueryVariable('state'),
    }).then(res => {
      this.detail = res.data
    })
  },
  methods: {
    prevImg(img){
      ImagePreview([img]);
    }
  },
};
</script>

<style lang="scss" scoped>
.violation {
  padding: 20px;
  background-color: #ffffff;
  min-height: 100vh;
  .title {
    font-size: 16px;

  }

  .warn {
    font-size: 14px;
  }
  .date {
    font-size: 14px;
    margin-top: 10px;
  }
}
</style>